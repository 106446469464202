// We haven't added icon's computed property because it makes this mixin coupled with UI
export const formatos = {
  data() {
    return {
      // passwordFieldType: 'password',
    }
  },
  methods: {
    divideRut(rut) {
      const rutNum = rut.replace('-', '')
                      .replace('.', '').replace('.', '')
      const rutSinDv = rutNum.substring(0, rutNum.length - 1)
      const dv = rutNum.substring(rutNum.length - 1, rutNum.length)
      return {
        rutSinDv,
        dv
      }
    },
    formatHTMLSweetEliminar(modulo, texto, info) {
      const infoDiv = info !== '' && typeof info !== 'undefined'
        ? `<div
            class="text-secondary mb-75"
            style="font-size: 1.1rem;"
          >
           Info: "${info}"
          </div>`
        : ''
      const textoDiv = texto !== '' && typeof texto !== 'undefined'
        ? `<spam
            class="text-secondary"
            style="font-size: 1.1rem;"
          >
            "${texto}"
          </spam>`
        : ''
      const moduloDiv = modulo !== '' && typeof modulo !== 'undefined'
          ? `<p class="text-center mb-50">
              ¿Está seguro(a) de eliminar ${modulo}?
            </p>`
          : ''
      return `
      <div class="mt-75 mb-75">
        ${infoDiv}
        ${moduloDiv}
        ${textoDiv}
      </div>
      `
    },
    formatHTMLSweetInfo(modulo, info) {

      const infoDiv = info !== ''
        ? `<i
            class="text-secondary"
            style="font-size: 1rem;"
          >
            Info: "${info}"
          </i>`
        : ''
      return `
        <div class="mt-75 mb-50">
          ${infoDiv}
          <p class="mb-1 mt-1 text-center">
            ¿Está seguro(a) de actualizar ${modulo}?
          </p>
        </div>
      `
    },

    // EJEMPLO: Jueves 06 de junio del 2021
    formatFechaVerCompleto(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ];

      const meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciciembre',

      ];

      const date = new Date(fecha)
      let nombreDia = dias[date.getDay()]
      let nombreMes = meses[date.getMonth()]

      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${nombreDia} ${dia} de ${nombreMes} del ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // EJEMPLO: Jueves 06 de junio
    formatDiaMesCompleto(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ];

      const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciciembre',

      ];

      const date = new Date(fecha)
      let nombreDia = dias[date.getDay()]
      let nombreMes = meses[date.getMonth()]

      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      return `${nombreDia} ${dia} de ${nombreMes}`
    },

    // EJEMPLO: Jue, 06-06-2021
    formatFechaVer(fecha) {
      const dias = [
        'Lun',
        'Mar',
        'Mié',
        'Jue',
        'Vie',
        'Sáb',
        'Dom',
      ];

      const meses = [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
      ];

      const date = new Date(fecha)
      let nombreDia = dias[date.getDay()]
      let nombreMes = meses[date.getMonth()]
      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${nombreDia}, ${dia} ${nombreMes} ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // EJEMPLO: Jue, 06-06-2021
    formatFechaVerActividades(fecha) {
      const dias = [
        'Dom',
        'Lun',
        'Mar',
        'Mié',
        'Jue',
        'Vie',
        'Sáb',

      ];

      const meses = [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',

      ];
      const date = new Date(fecha)
      let nombreDia = dias[date.getDay()]
      let nombreMes = meses[date.getMonth()]
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${nombreDia}, ${dia} ${nombreMes} ${year}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // EJEMPLO: Jueves 06
    formatFechaDía(fecha) {
      const dias = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ];
      const date = new Date(fecha)
      let nombreDia
      if (date.getDay() === 0) {
        nombreDia = 'Domingo'
      } else {
        nombreDia = dias[date.getDay() -1]
      }
      const dia = date.getDate() + 1 > 9
        ? date.getDate()
        : `0${date.getDate()}`


      return `${nombreDia} ${dia}`

      // OBTIENE EL NUM DEL MES
      // const mes = (date.getMonth() + 1) > 9
      //   ? (date.getMonth() + 1)
      //   : `0${(date.getMonth() + 1)}`
    },

    // 2023-06-16 22:00
    formatoFechaCalendar(fecha, hora) {
      const date = new Date(fecha)
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() + 1 > 9
        ? date.getDate() + 1
        : `0${date.getDate() + 1}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia} ${hora}`
    },

    // 2023-06-16
    formatoFechaHoy() {
      const date = new Date();

      const year = date.getFullYear();

      // Obtiene el mes y asegura que tenga dos dígitos
      const mes = date.getMonth() + 1 > 9
        ? (date.getMonth() + 1).toString()
        : `0${date.getMonth() + 1}`;

      // Obtiene el día y asegura que tenga dos dígitos
      const dia = date.getDate() > 9
        ? date.getDate().toString()
        : `0${date.getDate()}`;

      return `${year}-${mes}-${dia}`;
    },

    formatoFechaHoyDiaMesAno() {
      const date = new Date();

      const year = date.getFullYear();

      // Obtiene el mes y asegura que tenga dos dígitos
      const mes = date.getMonth() + 1 > 9
        ? (date.getMonth() + 1).toString()
        : `0${date.getMonth() + 1}`;

      // Obtiene el día y asegura que tenga dos dígitos
      const dia = date.getDate() > 9
        ? date.getDate().toString()
        : `0${date.getDate()}`;

      return `${dia}-${mes}-${year}`;
    },

    formatoFechaHoyCalendar() {
      const date = new Date()
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() + 1 > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia}`
    },

    // 16-06-2023
    formatoDiaMesYear(fecha) {
      if (!fecha) return '';

      let parsedDate;

      // Detectar y manejar el formato dd-mm-YYYY
      const datePattern = /^(\d{2})-(\d{2})-(\d{4})$/;
      if (datePattern.test(fecha)) {
        const [, day, month, year] = fecha.match(datePattern);
        // Crear la fecha en UTC para evitar problemas de zona horaria
        parsedDate = new Date(Date.UTC(year, month - 1, day));
      } else {
        // Intentar parsear otros formatos reconocidos por Date
        parsedDate = new Date(fecha);
      }

      // Verifica si la fecha es válida
      if (isNaN(parsedDate)) {
        console.error('Fecha no válida:', fecha);
        return ''; // Devuelve una cadena vacía si la fecha no es válida
      }

      // Formatear la fecha en dd-mm-YYYY
      const dayFormatted = (`0${parsedDate.getUTCDate()}`).slice(-2); // Obtiene el día y lo formatea a dos dígitos
      const monthFormatted = (`0${parsedDate.getUTCMonth() + 1}`).slice(-2); // Obtiene el mes (base 0) y lo formatea a dos dígitos
      const yearFormatted = parsedDate.getUTCFullYear(); // Obtiene el año

      return `${dayFormatted}-${monthFormatted}-${yearFormatted}`; // Retorna la fecha en el formato dd-mm-YYYY
    },

    // 16-06-2023
    formatDateBlur(fecha) {
      // Filtrar cualquier carácter que no sea un número
      let sanitizedInput = fecha.replace(/[^0-9]/g, '');

      // Verifica la longitud del input para ir aplicando el formato
      if (sanitizedInput.length <= 2) {
        // Solo día
        return sanitizedInput;
      } else if (sanitizedInput.length <= 4) {
        // Día y mes
        return `${sanitizedInput.slice(0, 2)}-${sanitizedInput.slice(2, 4)}`;
      } else if (sanitizedInput.length <= 8) {
        // Día, mes y año
        return `${sanitizedInput.slice(0, 2)}-${sanitizedInput.slice(2, 4)}-${sanitizedInput.slice(4, 8)}`;
      }

      // Si el input tiene más de 8 dígitos, truncar a la fecha completa
      return `${sanitizedInput.slice(0, 2)}-${sanitizedInput.slice(2, 4)}-${sanitizedInput.slice(4, 8)}`;
    },

    // 16-06
    formatoDiaMes(fecha) {
      let fechaFinal
      if (fecha !== null) {
        const fechaSeparada = fecha.split('-')
        fechaFinal = `${fechaSeparada[2]}-${fechaSeparada[1]}`
      }
      return fechaFinal
    },

    // 2023-06-16
    formatoFecha(fecha) {
      const date = new Date()
      const mes = date.getMonth() > 8
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
      const dia = date.getDate() > 9
        ? date.getDate()
        : `0${date.getDate()}`

      const year = date.getFullYear()

      return `${year}-${mes}-${dia}`
    },

    // 13:30 hrs
    formatHoraVer(date) {
      const hora = date.split(':')[0]
      const minutos = date.split(':')[1]
      const horario = `${hora}:${minutos} hrs`
      return horario
    },

    // 13:30
    formatHoraInput(date) {
      const hora = date.split(':')[0]
      let minutos = date.split(':')[1]
      if (typeof minutos === 'undefined') {
        // minutos = '00'
      }
      let horario = `${hora}:${minutos}`
      if (minutos === 'undefined') {
        horario = hora
      }

      return horario
    },

    formatHorasPedagogicasSemanales(value) {
      if (value == null) { return false }
      let formattedValue = value
      // Corrige si el primer dígito es '0' y el valor no es solo '0'
      if (formattedValue.startsWith('0') && formattedValue !== '0') {
        formattedValue = '1' + formattedValue.slice(1)
      }

      if (formattedValue.includes('.')) {
        const parts = formattedValue.split('.')
        if (parts[1]) {
          const decimal = parts[1][0] // Solo considera el primer dígito después del punto
          if (decimal !== '0' && decimal !== '5') {
            formattedValue = parts[0] + '.' + (decimal < '5' ? '0' : '5')
          } else {
            formattedValue = parts[0] + '.' + decimal
          }
        }
      }
      return formattedValue;
    },

    formatHorasPedagogicasSemanalesBlur(value) {
      if (value == null) { return false }
      let formattedValue = value
      // Corrige si el primer dígito es '0' y el valor no es solo '0'
      if (formattedValue.startsWith('0') && formattedValue !== '0') {
        formattedValue = '1' + formattedValue.slice(1)
      }
      if (formattedValue.includes('.')) {
        const parts = formattedValue.split('.')
        if (parts[1] === '' || parts[1] === null) {
            formattedValue = parts[0] + '.0'
        }
      }
      return formattedValue;
    },
    formatSoloNumeros(number){
      number = number.toString()
      const expReg = /^[0-9]*(\.?)[ 0-9]+$/
      let numero
      if (number.match(expReg)){
        numero = number
      } else if (number === '') {
        numero = 0
      } else if (number === null) {
        numero = 0
      } else {
        numero = 0
      }
      return numero.toString()
    },

    // * Solo números
    //* Entre 0 - 100
    formatPorcentajeEvaluacion(number) {
      const expReg = /^[0-9]+$/
      let numero
      if (number.match(expReg)) {
        numero = number
      } else if (number === '') {
        numero = 0
      } else {
        numero = 0
      }
      numero = numero > 100 ? 100 : numero;

      return numero
    },

    formatPorcentajeEvaluacionAcumulativa(number) {
      const expReg = /^[0-9]+$/
      let numero
      if (number.match(expReg)) {
        numero = number
      }
      numero = numero > 100 ? 100 : numero;

      return numero
    },

    // * Solo números
    // * Max 6 caracteres
    formatCodigoFirma(txt){
      const expReg = /^[0-9]+$/
      let numero
      if (txt.match(expReg)){
        numero = txt
      } else {
        numero = ''
      }

      if (numero.length > 6) {
        numero = numero.slice(0, 6);
      }

      return numero
    },

    formatSoloNumerosUnDigito(number){
      let unDigito = 0
      unDigito = number[0] > 8 ? '8' : number[0]
      const expReg = /^[0-9]+$/
      let numero
      if (typeof unDigito === 'undefined' || unDigito === '' || unDigito === null) {
        numero = '0'
      } else if (unDigito.match(expReg)){
        numero = unDigito
      } else {
        numero = '0'
      }

      return numero
    },

    formatSoloNumerosMaxLenght(number, max){
      const expReg = /^[0-9]*(\.?)[ 0-9]+$/
      const lengthCorte = max
      let numero
      if (number.match(expReg)){
        numero = number.substring(0, lengthCorte)
      } else {
        numero = number.substring(0, number.length - 1)
        const ultimoCaracter = number.substring(number.length -2, number.length -1)
        if (ultimoCaracter === "`"
          || ultimoCaracter === "´"
          || ultimoCaracter === "¨"
          || ultimoCaracter === "^") {
          numero = numero.substring(0, numero.length - 1)
        }
      }
      return numero
    },

    formatNota(n){
      let number = String(n)
      number = number.replace('.', '')
      if (number.length > 2) {
        number = number[0]+''+number[1]
      }
      if (number.substring(0,1) === '0') {
        number = number.substring(1)
      }
      if (number === '8' || number === '9') {
        number = '0'
      }
      const expReg = /^[0-9]+$/
      let numero = Number
      if (number.match(expReg)){
        numero = number
      } else if (number === '') {
        // numero = 0
      } else {
        // numero = 0
      }

      if (numero.length > 2) {
        numero = numero[0]+''+numero[1]
      } else if (numero > 70) {
        numero = 70
      }
      const numeroUno = typeof numero[0] !== 'undefined' ? numero[0] : '0'
      const numeroDos = typeof numero[1] !== 'undefined' ? '.'+numero[1] : ''
      numero = numeroUno+''+numeroDos
      return numero
    },

    formatColor(n) {
      const nota = parseInt(n)
      if (nota === 0) {
        this.textColor = 'text-dark'
      } else if (nota >= 1 && nota < 4) {
        this.textColor = 'text-danger'
      }else if (nota > 9 && nota < 40) {
        this.textColor = 'text-danger'
      } else if (nota >= 4 && nota <= 7){
        this.textColor = 'text-primary'
      } else if (nota > 39 && nota < 71) {
        this.textColor = 'text-primary'
      } else if (nota > 70 ) {
        this.textColor = 'text-dark'
      }
    },
  },
}

export const _ = null
